<template>
	<div>
		<BackButton :route="{ name: 'content-pages', label: 'Pages' }" />

		<ContentBuilder
			ref="builder"
			url-prefix="/"
			edit-route="content-pages-id"
			page-type="Page"
			:clone-id="id"
			@created="created"
		/>
	</div>
</template>

<script>
import { computed, inject } from 'vue'
import { useToast } from 'vue-toastification'

import getEnv from '@/extensions/env'

export default {
	components: {},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			id: this.$route.query.id || null,
		}
	},
	mounted() {},
	methods: {
		created(slug) {
			this.$router.push({ name: 'content-pages-id', params: { id: slug } })
		},
	},
}
</script>
